import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import Banner from "../../components/includes/Banner";
import { useTheme } from "@emotion/react";
import SecurityIcon from "@mui/icons-material/Security";
import LockIcon from "@mui/icons-material/Lock";
import SpeedIcon from "@mui/icons-material/Speed";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import AcUnitIcon from "@mui/icons-material/AcUnit";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const Home = () => {
  const theme = useTheme();
  const aviationSpecs = [
    {
      icon: <ShuffleIcon color="secondary" />,
      name: "Flexibilité",
    },
    {
      icon: <SecurityIcon color="secondary" />,
      name: "Sécurité",
    },
    {
      icon: <AcUnitIcon color="secondary" />,
      name: "Confort",
    },
    {
      icon: <SpeedIcon color="secondary" />,
      name: "Rapidité",
    },
    {
      icon: <PrecisionManufacturingIcon color="secondary" />,
      name: "Productivité",
    },
    {
      icon: <LockIcon color="secondary" />,
      name: "Confidentialité",
    },
  ];
  const airs = [
    "/airs/airFrance.png",
    "/airs/egyptair.png",
    "/airs/emirates.png",
    "/airs/etihad.png",
    "/airs/turkish.png",
  ];
  return (
    <Grid>
      <Banner />
      <Container sx={{ my: 5 }}>
        <Grid container direction={"row"} sx={{ p: 3 }} spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography>
              <img
                src="/aeroport.jpg"
                alt="Aviation d'affaires"
                width={"100%"}
                style={{ borderRadius: 20 }}
              />
            </Typography>
          </Grid>
          <Grid
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            item
            xs={12}
            md={6}
          >
            <Container>
              <Typography variant="h3" color={theme.palette.primary.main}>
                Aviation d'affaires
              </Typography>
              <Typography
                variant="subtitle1"
                fontSize={23}
                color={theme.palette.secondary.main}
              >
                Affrètement d'avion, Location Jet privé
              </Typography>
              <Typography sx={{ my: 2 }} fontSize={20}>
                Fini les longues fils d'attentes aux aéroports et les temps
                perdu! <br />
                Optez pour la location d'un jet privé ou affrètement d'avion,
                choisissez vos horaires, impératifs et services à bord et nous
                faisons le reste.
              </Typography>

              <Grid
                container
                justifyContent={"center"}
                alignItems={"center"}
                sx={{ my: 2 }}
                spacing={2}
              >
                {aviationSpecs.map((el) => (
                  <Grid sx={{ display: "inline-block" }} item xs={6} md={4}>
                    <Button size="large" color="inherit" startIcon={el.icon}>
                      {el.name}{" "}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </Container>
          </Grid>
        </Grid>
        <Box sx={{ p: 3 }}>
          <Divider />
        </Box>
      </Container>

      <Container>
        <Grid container direction={"row-reverse"} sx={{ p: 3 }} spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography>
              <img
                src="/billet.jpg"
                alt="Billets"
                width={"100%"}
                style={{ borderRadius: 20 }}
              />
            </Typography>
          </Grid>
          <Grid
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            item
            xs={12}
            md={6}
          >
            <Box>
              <Typography color={theme.palette.primary.main} variant="h3">
                Vente billets d'avion
              </Typography>
              <Typography
                color={theme.palette.secondary.main}
                variant="subtitle1"
                fontSize={23}
              >
                Billets d'avion pas cher.
              </Typography>
              <Typography textAlign={"justify"} fontSize={20}>
                Etant une agence de voyage, nous faisons des réservations et
                achats de billets de d'avion dans le meilleur délai et à
                meilleurs coûts; et proposons des alternatives en cas de
                changement ou annulation du vols. <br />
                Nous disposons d'un service de protocol qui s'occupe de
                l'ensemble de démarches aéroportuaires et assure au mininum les
                rôles inhérents au départ comme à l'arrivé et rempli toutes les
                formalités administratives et migratoires de vos personnels,
                membres de famille, et/ou invités.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ p: 3 }}>
          <Divider />
        </Box>
      </Container>

      <Container>
        <Grid container direction={"row"} sx={{ p: 3 }} spacing={3}>
          <Grid item xs={12} md={6}>
            {/* <Typography color={theme.palette.secondary.main} variant="subtitle1">
            Billets d'avion pas cher.
          </Typography> */}
            <Typography>
              <img
                src="/fabel6.jpg"
                alt="visa"
                width={"100%"}
                style={{ borderRadius: 20 }}
              />
            </Typography>
          </Grid>
          <Grid
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            item
            xs={12}
            md={6}
          >
            <Box>
              <Typography
                color={theme.palette.primary.main}
                fontSize={23}
                variant="h3"
              >
                Facilitation Visa
              </Typography>
              <Typography fontSize={20}>
                Nous nous occupons de l'ensemble de vos démarches pour
                l'obtention de vos visas. Nous vous facilitons aussi les
                démarches pour les destinations réputées dificiles.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ p: 3 }}>
          <Divider />
        </Box>
      </Container>

      <Container>
        <Grid container direction={"row-reverse"} sx={{ p: 3 }} spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography>
              <img
                src="/fret.jpg"
                alt="Fret"
                width={"100%"}
                style={{ borderRadius: 20 }}
              />
            </Typography>
          </Grid>
          <Grid
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            item
            xs={12}
            md={6}
          >
            <Box>
              <Typography color={theme.palette.primary.main} variant="h3">
                Frêt
              </Typography>
              <Typography
                color={theme.palette.secondary.main}
                variant="subtitle1"
                fontSize={23}
              >
                Cargo, Courrier, entrepôsage et manutention
              </Typography>
              <Typography fontSize={20}>
                Vous êtes à la recherche d'une agence de frêt et courrier
                offrant un service de livraison à la hauteur de vos attentes
                24h/24, 7j/7 et toujours d'une extrême efficacité? <br />
                Avec Fabel Travel, soyez rassuré que vos colis et marchandises
                seront réçus dans les délais qui correspondent à vos besoins de
                livraison.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ p: 3 }}>
          <Divider />
        </Box>
      </Container>

      <Container>
        <Grid container direction={"row"} sx={{ p: 3 }} spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography>
              <img
                src="/carRental.jpg"
                alt="car rental"
                width={"100%"}
                style={{ borderRadius: 20 }}
              />
            </Typography>
          </Grid>
          <Grid
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            item
            xs={12}
            md={6}
          >
            <Box>
              <Typography color={theme.palette.primary.main} variant="h3">
                Location de véhicules
              </Typography>
              <Typography fontSize={20}>
                Fabel Travel vous offre un service de location de véhicule de
                qualité, disposant ainsi d'un charroi automobile de plusieurs
                véhicule.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default Home;
